import $ from 'jquery'
import 'bootstrap-select'
import toastr from 'toastr'
import { jx, requestMessage } from './global'
import { K_ANAHTARI, OTURUMID } from './constants'

require('datatables.net-dt')()

export default {
  renderDt() {
    $(document).ready(function () {
      $('#uygulamaDatatable').DataTable()
      $('.selectpicker').selectpicker()
      uygulamaListele()
    })
  },
}

function uygulamaListele() {
  jx('Uygulama/UygulamaListele', 'POST', {
    'K_ANAHTAR': K_ANAHTARI,
    'OTURUM': OTURUMID,
    'UygulamaID': 0
  }).then(res => {
    $('#uygulamaDatatable').DataTable().destroy()
    $('#uygulamaDatatable tbody').html('')
    const data = JSON.parse(res.NESNE) !== null ? JSON.parse(res.NESNE) : []
    for (let d of data) {
      $('#uygulamaDatatable tbody').append(`
        <tr>
          <td>${d.UygulamaAdi}</td>
          <td>
            <div class="dropdown">
              <button class="btn btn-sm btn-secondary dropdown-toggle"
                type="button"
                data-toggle="dropdown">
                <i class="fa fa-cog"></i> İşlemler
              </button>
              <div class="dropdown-menu" data-id="${d.TabloID}">
                <a class="dropdown-item uygulamaHesapEslentirBtn" href="javascript:;">Hesap Eşleştir</a>
                <a class="dropdown-item uygulamaDuzenleBtn" href="javascript:;">Düzenle</a>
                <a class="dropdown-item uygulamaSilBtn" href="javascript:;">Sil</a>
                </div>
            </div>
          </td>
        </tr>
      `)
    }
    $('#uygulamaDatatable').DataTable()
    hesapEslestir()
    uygulamaEkle()
    uygulamaDuzenle()
    uygulamaSil()
  })
}
function hesapEslestir() {
  $('#eslestir').on('hidden.bs.modal', function () {
    $('#hesapSelectBox').selectpicker('deselectAll')
  })
  let uyugulamaId = ''
  $('.uygulamaHesapEslentirBtn').on('click', function () {
    uyugulamaId = $(this).parent().data('id')
    jx('Hesap/HesapListele', 'POST', {
      'K_ANAHTAR': K_ANAHTARI,
      'OTURUM': OTURUMID,
      'ID_HESAP': 0
    }).then(val => {
      $('#hesapSelectBox').html('')
      const data = JSON.parse(val.NESNE) !== null ? JSON.parse(val.NESNE) : []
      for (let d of data) {
        $('#hesapSelectBox').append(
          `<option value="${d.TabloID}">${d.OP_TELEFON}</option>`
        )
      }
      $('#hesapSelectBox').selectpicker('refresh')
      $('#eslestir').modal('show')
    })
  })
  $('.hesapEslestirKaydetBtn').off('click')
  $('.hesapEslestirKaydetBtn').on('click', function () {
    jx('Uygulama/UygulamaHesapEslestir', 'POST', {
      'K_ANAHTAR': K_ANAHTARI,
      'OTURUM': OTURUMID,
      'HesapID': $('#hesapSelectBox').val().toString(),
      'UygulamaID': uyugulamaId
    }).then(res => {
      console.log(uyugulamaId)
      requestMessage(res)
      $('#eslestir').modal('hide')
    })
  })
}

function uygulamaEkle() {
  $('.uygulamaEkleBtn').off('click')
  $('.uygulamaEkleBtn').on('click', function () {
    if ($('#uygulamaAd').val() === '') {
      toastr.warning('Lütfen değer giriniz')
    } else {
      const obj = {
        'K_ANAHTAR': K_ANAHTARI,
        'OTURUM': OTURUMID,
        "UygulamAD": $("#uygulamaAd").val()
      }
      jx('/Uygulama/UygulamaEkle', 'POST', obj).then(res => {
        requestMessage(res)
        $('#uygulamaEkleModal').modal('hide')
        uygulamaListele()
      })
    }
  })
}

function uygulamaDuzenle() {
  $('#uygulamaAdId').empty("");
  $("#duzenleuygulamaAd").empty("");
  $('.uygulamaDuzenleBtn').off('click')
  $('.uygulamaDuzenleBtn').on('click', function () {
    $('#uygulamaDuzenleModal').modal('show')
    let id = $(this).parent().data('id')
    jx('Uygulama/UygulamaListele', 'POST', {
      'K_ANAHTAR': K_ANAHTARI,
      'OTURUM': OTURUMID,
      'UygulamaID': id
    }).then(res => {

      $('#uygulamaAdId').val(JSON.parse(res.NESNE)[0].TabloID)
      $('#duzenleuygulamaAd').val(JSON.parse(res.NESNE)[0].UygulamaAdi)
    })
    $('.uygulamaguncelleBtn').off('click')
    $('.uygulamaguncelleBtn').on('click', function () {
      if ($('#duzenleuygulamaAd').val() === '') {
        toastr.warning('Lütfen değer giriniz')
      } else {
        const obj = {
          'K_ANAHTAR': K_ANAHTARI,
          'OTURUM': OTURUMID,
          'UygulamaID': id,
          "UygulamAD": $("#duzenleuygulamaAd").val()
        }
        jx('/Uygulama/UygulamaGuncelle', 'POST', obj).then(res => {
          requestMessage(res)
          $('#uygulamaDuzenleModal').modal('hide')
          uygulamaListele()
        })
      }
    })
  })

}

function uygulamaSil () {
  $('#msjsil').empty('')
  $('.uygulamaSilBtn').off('click')
  $('.uygulamaSilBtn').on('click', function () {
    $('#uygulamaSilModal').modal('show')
    let id = $(this).parent().data('id')
    jx('Uygulama/UygulamaListele', 'POST', {
      'K_ANAHTAR': K_ANAHTARI,
      'OTURUM': OTURUMID,
      'UygulamaID': id
    }).then(res => {
      $('#msjsil').text(JSON.parse(res.NESNE)[0].UygulamaAdi + ' ' + ' Uygulamasını  silmek istiyor musunuz? ')
    })
    $('.uygulamaSilButton').off('click')
    $('.uygulamaSilButton').on('click', function () {
      const obj = {
        'K_ANAHTAR': K_ANAHTARI,
        'OTURUM': OTURUMID,
        'UygulamaID': id
      }
      jx('/Uygulama/UygulamaSil', 'POST', obj).then(res => {
        requestMessage(res)
        $('#uygulamaDuzenleModal').modal('hide')
        uygulamaListele()
      })
    })
  })
}
