import $ from 'jquery'
import 'bootstrap-select'
import toastr from 'toastr'
import { jx, requestMessage } from './global'
import { TR, K_ANAHTARI, OTURUMID } from './constants'

require('datatables.net-dt')()

export default {
  renderDt() {
    $(document).ready(function () {
      $('#hesapDatatable').DataTable()
      $('.selectpicker').selectpicker()
      hesapListele()
      operatorListele()
      hesapGuncelle()
    })
  }
}


function operatorListele() {
  jx('/Operator/OperatorListele', 'POST', {
    'K_ANAHTAR': K_ANAHTARI,
    'OTURUM': OTURUMID,
    'ID_OPERATOR': 0
  }).then(res => {
    $('#operator').empty('')
    var html = "";
    const data = JSON.parse(res.NESNE) !== null ? JSON.parse(res.NESNE) : []
    $('#operator').append('<option value="0" disabled="disabled" selected>  Operatör Seç  </option>');
    for (let d of data) {
      html += '<option value=' + d.TabloID + '>  ' + d.OperatorAdi + '  </option>'
    }
    $('#operator').append(html);
  })
}
function hesapListele() {
  jx('/Hesap/HesapListele', 'POST', {
    'K_ANAHTAR': K_ANAHTARI,
    'OTURUM': OTURUMID,
    'ID_HESAP': 0
  }).then(res => {
    $('#hesapDatatable').DataTable().destroy()
    $('#hesapDatatable tbody').html('')
    const data = JSON.parse(res.NESNE) !== null ? JSON.parse(res.NESNE) : []
    for (let d of data) {
      $('#hesapDatatable tbody').append(`
         <tr>
           <td>${d.OP_TELEFON}</td>
           <td>${d.OP_KULLANICI}</td>
           <td>${d.OperatorAdi}</td>
           <td>${d.KAYIT_TARIH}</td>
           <td>
             <div class="dropdown">
               <button class="btn btn-sm btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">
                 <i class="fa fa-cog"></i> İşlemler
               </button>
               <div class="dropdown-menu" data-id="${d.TabloID}">
                 <a class="dropdown-item hesapDuzenleBtn" href="javascript:;">Düzenle</a>
                 <a class="dropdown-item hesapSilBtn"  href="javascript:;">Sil</a>
               </div>
             </div>
           </td>
         </tr>
       `)
    }
    $('#hesapDatatable').DataTable()
    hesapGuncelle()
    hesapEkle()
    hesapSil()
  })

}

function hesapEkle() {
  $('#hesapEkleBtn').off('click')
  $('#hesapEkleBtn').on('click', function () {
    if ($('#operator option:selected').val() == '' || $('#operator option:selected').val() == 0) {
      toastr.warning('Lütfen operatör  seçiniz !!')
    }
    if ($('#operatorTel').val() == '' || $('#operatorTel').val() == 0) {
      toastr.warning('Operator Telefon alanını boş geçemezsiniz!!')
    }
    if ($('#operatorKul').val() === '' || $('#operatorKul').val() === 0 || $('#operatorKul').val() === undefined) {

      toastr.warning('Operatör Kullanıcı alanını boş geçemezsiniz!!')
    }
    if ($('#operatorSifre').val() === '' || $('#operatorSifre').val() === 0 || $('#operatorSifre').val() === undefined) {

      toastr.warning('Operatör Şifre  alanını boş geçemezsiniz!!')
    }

    if ($('#baglantiGuid').val() === '' || $('#baglantiGuid').val() === 0 || $('#baglantiGuid').val() === undefined) {
      toastr.warning('Baülantı guid  alanını boş geçemezsiniz!!')
    }

    if ($('#TrancId').val() === '' || $('#TrancId').val() === 0 || $('#TrancId').val() === undefined) {
      toastr.warning('Baülantı TrancId  alanını boş geçemezsiniz!!')
    }

    if ($('#ContextId').val() === '' || $('#ContextId').val() === 0 || $('#ContextId').val() === undefined) {
      toastr.warning('Baülantı TrancId  alanını boş geçemezsiniz!!')
    }
    if ($('#PBXID').val() === '' || $('#PBXID').val() === 0 || $('#PBXID').val() === undefined) {
      toastr.warning('Baülantı PBXID  alanını boş geçemezsiniz!!')
    }
    else {
      const obj = {
        'K_ANAHTAR': K_ANAHTARI,
        'OTURUM': OTURUMID,
        "ID_OPERATOR": $('#operator option:selected').val(),
        "OP_TELEFON": $('#operatorTel').val(),
        "OP_KULLANICI": $('#operatorKul').val(),
        "OP_SİFRE": $('#operatorSifre').val(),
        "BAGLANTI_GUID": $('#baglantiGuid').val(),
        "TrancId": $('#TrancId').val(),
        "ContextId": $('#ContextId').val(),
        "PBXID": $('#ContextId').val()
      }

      jx('/Hesap/HesapEkle', 'POST', obj).then(res => {
        requestMessage(res)
        $('#ekleModal').modal('hide')
        hesapListele()
        operatorListele()
        hesapSil()
      })
    }
  })


}

function hesapGuncelle() {
  $('.hesapDuzenleBtn').off('click')
  $('.hesapDuzenleBtn').on('click', function () {
    $('#duzenleModal').modal('show')
    let id = $(this).parent().data('id')
    jx('/Hesap/HesapListele', 'POST', {
      'K_ANAHTAR': K_ANAHTARI,
      'OTURUM': OTURUMID,
      'ID_HESAP': id
    }).then(res => {
      $('#duzenleidhesap').val(JSON.parse(res.NESNE)[0].TabloID),
        $('#duzenleoperatorTel').val(JSON.parse(res.NESNE)[0].OP_TELEFON),
        $('#duzenleoperatorKul').val(JSON.parse(res.NESNE)[0].OP_KULLANICI),
        $('#duzenleSifre').val(JSON.parse(res.NESNE)[0].OP_SİFRE),
        $('#duzenlebaglantiGuid').val(JSON.parse(res.NESNE)[0].BAGLANTI_GUID),
        $('#duzenleTrancId').val(JSON.parse(res.NESNE)[0].TrancId),
        $('#duzenleContextId').val(JSON.parse(res.NESNE)[0].ContextId),
        $('#duzenlePBXID').val(JSON.parse(res.NESNE)[0].PBXID),

        jx('/Operator/OperatorListele', 'POST', {
          'K_ANAHTAR': K_ANAHTARI,
          'OTURUM': OTURUMID,
          'ID_OPERATOR': 0
        }).then(res => {
          var html = "";
          $('#duzenleoperator').empty("");
          const data = JSON.parse(res.NESNE) !== null ? JSON.parse(res.NESNE) : []
          for (let d of data) {

            html += '<option value=' + d.TabloID + '>  ' + d.OperatorAdi + '  </option>'
          }
          // $('#duzenleoperator').selectpicker('refresh')
          $('#duzenleoperator').append(html);
        })
      let idopetor = JSON.parse(res.NESNE)[0].ID_OPERATOR
      jx('/Operator/OperatorListele', 'POST', {
        'K_ANAHTAR': K_ANAHTARI,
        'OTURUM': OTURUMID,
        'ID_OPERATOR': idopetor
      }).then(res => {
        $('#duzenleoperator').selectpicker('refresh')
        $('#duzenleoperator').selectpicker('val', JSON.parse(res.NESNE)[0].TabloID)
      })

      $('#DuzenleBtn').off('click')
      $('#DuzenleBtn').on('click', function () {
        if ($('#duzenleoperator option:selected').val() == '' || $('#duzenleoperator option:selected').val() == 0) {
          toastr.warning('Lütfen operatör  seçiniz !!')
        }
        if ($('#duzenleoperatorTel').val() == '' || $('#duzenleoperatorTel').val() == 0) {
          toastr.warning('Operator Telefon alanını boş geçemezsiniz!!')
        }
        if ($('#duzenleoperatorKul').val() === '' || $('#duzenleoperatorKul').val() === 0 || $('#duzenleoperatorKul').val() === undefined) {

          toastr.warning('Operatör Kullanıcı alanını boş geçemezsiniz!!')
        }
        if ($('#duzenleSifre').val() === '' || $('#duzenleSifre').val() === 0 || $('#duzenleSifre').val() === undefined) {

          toastr.warning('Operatör Şifre  alanını boş geçemezsiniz!!')
        }
        if ($('#duzenlebaglantiGuid').val() === '' || $('#duzenlebaglantiGuid').val() === 0 || $('#duzenlebaglantiGuid').val() === undefined) {
          toastr.warning('Baülantı guid  alanını boş geçemezsiniz!!')
        }
        if ($('#duzenleTrancId').val() === '' || $('#duzenleTrancId').val() === 0 || $('#duzenleTrancId').val() === undefined) {
          toastr.warning('Baülantı TrancId  alanını boş geçemezsiniz!!')
        }
        if ($('#duzenleContextId').val() === '' || $('#duzenleontextId').val() === 0 || $('#duzenleContextId').val() === undefined) {
          toastr.warning('Baülantı TrancId  alanını boş geçemezsiniz!!')
        }
        if ($('#duzenlePBXID').val() === '' || $('#duzenlePBXID').val() === 0 || $('#duzenlePBXID').val() === undefined) {
          toastr.warning('Baülantı PBXID  alanını boş geçemezsiniz!!')
        }
        else {
          const objdata = {
            'K_ANAHTAR': K_ANAHTARI,
            'OTURUM': OTURUMID,
            "ID_HESAP": $('#duzenleidhesap').val(),
            "ID_OPERATOR": $('#duzenleoperator option:selected').val(),
            "OP_TELEFON": $('#duzenleoperatorTel').val(),
            "OP_KULLANICI": $('#duzenleoperatorKul').val(),
            "OP_SİFRE": $('#duzenleSifre').val(),
            "BAGLANTI_GUID": $('#duzenlebaglantiGuid').val(),
            "TrancId": $('#duzenleTrancId').val(),
            "ContextId": $('#duzenleContextId').val(),
            "PBXID": $('#duzenlePBXID').val()
          }
          jx('/Hesap/HesapGuncelle', 'POST', objdata).then(res => {
            requestMessage(res)
            $('#duzenleModal').modal('hide')
            hesapListele()
            operatorListele()
          })
        }
      })
    })
  })
}

function hesapSil() {
  $('.hesapSilBtn').off('click')
  $('.hesapSilBtn').on('click', function () {
  $('#HesapSilModal').modal('show')
    let id = $(this).parent().data('id')
    $('.SilButton').off('click')
    $('.SilButton').on('click', function () {
      const obj = {
        'K_ANAHTAR': K_ANAHTARI,
        'OTURUM': OTURUMID,
        'ID_HESAP': id
      }
      jx('/Hesap/HesapSil', 'POST', obj).then(res => {
        requestMessage(res)
        $('#HesapSilModal').modal('hide')
        hesapListele()
      })
    })
  })
}