import '../styles/index'
import $ from 'jquery'
import popper from 'popper.js'
import bootstrap from 'bootstrap'
import { loadByHash } from './global'

$('#nav').load('./layouts/nav.html')
$('#aside').load('./layouts/aside.html', function () {
  loadByHash()
})
