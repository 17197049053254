import $ from 'jquery'
import 'bootstrap-select'
import toastr from 'toastr'
import { jx, requestMessage } from './global'
import { K_ANAHTARI, OTURUMID } from './constants'

require('datatables.net-dt')()

export default {
  renderDt () {
    $(document).ready(function () {
      $('#yetkiDatatable').DataTable()
      $('.selectpicker').selectpicker()
    })
  },
  yetkiListeleme () {
    yetkiListele()
  }
}

function yetkiSil () {
  $('.yetkiSilDropdownBtn').off('click')
  $('.yetkiSilDropdownBtn').on('click', function () {
    jx('YetkiGrup/YetkiGrupSil', 'POST', {
      'K_ANAHTAR': K_ANAHTARI,
      'OTURUM': OTURUMID,
      'ID_YETKIGRUP': $(this).parent().data('id')
    }).then(res => {
      console.log(res)
      requestMessage(res)
      yetkiListele()
    })
  })
}

function yetkiListele () {
  jx('YetkiGrup/YetkiGrupListele', 'POST', {
    'K_ANAHTAR': K_ANAHTARI,
    'OTURUM': OTURUMID,
    'ID_YETKIGRUP': 0
  }).then(res => {
    $('#yetkiDatatable').DataTable().destroy()
    $('#yetkiDatatable tbody').html('')
    const data = JSON.parse(res.NESNE) !== null ? JSON.parse(res.NESNE) : []
    for (let d of data) {
      $('#yetkiDatatable tbody').append(`
        <tr>
          <td>${d.AD}</td>
          <td>
            <div class="dropdown">
              <button class="btn btn-sm btn-secondary dropdown-toggle"
                type="button"
                data-toggle="dropdown">
                <i class="fa fa-cog"></i> İşlemler
              </button>
              <div class="dropdown-menu" data-id="${d.ID_YETKIGRUP}">
                <a class="dropdown-item yetkiDuzenleDropdownBtn" href="javascript:;">Düzenle</a>
                <a class="dropdown-item yetkiSilDropdownBtn" href="javascript:;">Sil</a>
              </div>
            </div>
          </td>
        </tr>
      `)
    }
    yetkiSil()
    yetkiEkle()
    yetkiGuncelle()
    $('#yetkiDatatable').DataTable()
  })
}

function yetkiEkle () {
  $('#yetkiEkleBtn').off('click')
  $('#yetkiEkleBtn').on('click', function () {
    if ($('#yetkiAd').val() === '') {
      toastr.warning('Lütfen değer giriniz')
    } else {
      const obj = {
        'K_ANAHTAR': K_ANAHTARI,
        'OTURUM': OTURUMID,
        'AD': $('#yetkiAd').val()
      }
      jx('YetkiGrup/YetkiGrupEkle', 'POST', obj).then(res => {
        requestMessage(res)
        $('#yetkiAd').val('')
        $('#yetkiEkleModal').modal('hide')
        yetkiListele()
      })
    }
  })
}

function yetkiGuncelle () {
  $('.yetkiDuzenleDropdownBtn').off('click')
  $('.yetkiDuzenleDropdownBtn').on('click', function () {
    $('#yetkiGuncelleModal').modal('show')
    let id = $(this).parent().data('id')
    jx('YetkiGrup/YetkiGrupListele', 'POST', {
      'K_ANAHTAR': K_ANAHTARI,
      'OTURUM': OTURUMID,
      'ID_YETKIGRUP': id
    }).then(res => {
      $('#guncelleYetkiAd').val(JSON.parse(res.NESNE)[0].AD)
    })
    $('#yetkiGuncelleBtn').off('click')
    $('#yetkiGuncelleBtn').on('click', function () {
      jx('YetkiGrup/YetkiGrupGuncelle', 'POST', {
        'K_ANAHTAR': K_ANAHTARI,
        'OTURUM': OTURUMID,
        'ID_YETKIGRUP': id,
        'AD': $('#guncelleYetkiAd').val()
      }).then(res => {
        requestMessage(res)
        $('#guncelleYetkiAd').val('')
        $('#yetkiGuncelleModal').modal('hide')
        yetkiListele()
      })
    })
  })
}
