import $ from 'jquery'
import toastr from 'toastr'
import { CALLKEY } from './constants'
import { jx, requestMessage } from './global'

const arama = () => {
  $('#aramaBaslatBtn').on('click', function () {
    const obj = {
      'Key': CALLKEY,
      'SubeTelefon': '8503028324',
      'ArayanTelefon': $('#tel1').val(),
      'ArananTelefon': $('#tel2').val()
    }
    if ($('#tel1').val() === '' || $('#tel2').val() === '') {
      toastr.warning('Lütfen telefon numaralarını giriniz')
    } else {
      jx('Arama/CagriBaslat', 'POST', obj).then(res => {
        requestMessage(res)
      })
    }
  })
}

export default arama
