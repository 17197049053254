import $ from 'jquery'
import 'bootstrap-select'
import { TR, K_ANAHTARI, OTURUMID } from './constants'
import { jx, requestMessage } from './global'

require('datatables.net-dt')()

export default {
  renderDt() {
    $('#aramaKayitlari').DataTable()
    $('.selectpicker').selectpicker()

    jx('Uygulama/UygulamaListele', 'POST', {
      'K_ANAHTAR': K_ANAHTARI,
      'OTURUM': OTURUMID,
      'UygulamaID': 0
    }).then(res => {
      $('#ID_UYGULAMA').html('')
      $('#ID_UYGULAMA').append(`<option selected  value="">Tümü </option>`)
      const data = JSON.parse(res.NESNE) !== null ? JSON.parse(res.NESNE) : []
      for (let d of data) {
        $('#ID_UYGULAMA').append(
          `<option value="${d.TabloID}">${d.UygulamaAdi}</option>`
        )
      }
      $('#ID_UYGULAMA').selectpicker('refresh')
    })

    jx('Hesap/HesapListele', 'POST', {
      'K_ANAHTAR': K_ANAHTARI,
      'OTURUM': OTURUMID,
      'ID_HESAP': 0
    }).then(res => {
      $('#ID_HESAP').html('')
      $('#ID_HESAP').append(`<option selected  value="">Tümü </option>`)
      const data = JSON.parse(res.NESNE) !== null ? JSON.parse(res.NESNE) : []
      for (let d of data) {
        $('#ID_HESAP').append(
          `<option value="${d.TabloID}">${d.OP_TELEFON}</option>`
        )
      }
      $('#ID_HESAP').selectpicker('refresh')
    })

    jx('Operator/OperatorListele', 'POST', {
      'K_ANAHTAR': K_ANAHTARI,
      'OTURUM': OTURUMID,
      'ID_OPERATOR': 0
    }).then(res => {
      $('#ID_OPERATOR').html('')
      $('#ID_OPERATOR').append(`<option selected  value="">Tümü </option>`)
      const data = JSON.parse(res.NESNE) !== null ? JSON.parse(res.NESNE) : []
      for (let d of data) {
        $('#ID_OPERATOR').append(
          `<option value="${d.TabloID}">${d.OperatorAdi}</option>`
        )
      }
      $('#ID_OPERATOR').selectpicker('refresh')
    })
    $.fn.datepicker.languages['tr-TR'] = TR
    $('[data-toggle="datepicker"]').datepicker({
      language: 'tr-TR'
    })
  },
  aramaKayitListele () {
    $('#aramaKayitlariBtn').on('click', function () {
      if ($('#BASLANGICTARIH').val() === '') {
        return toastr.warning('Tarih boş olamaz')
      }

      const obj = {
        'K_ANAHTAR': K_ANAHTARI,
        'OTURUM': OTURUMID,
        'ID_UYGULAMA': $('#ID_UYGULAMA').val().toString(),
        'ID_OPERATOR': $('#ID_OPERATOR').val().toString(),
        'ID_HESAP': $('#ID_HESAP').val().toString(),
        'CAGRIPLATFORMID': $('#CAGRIPLATFORMID').val().toString(),
        'BASLANGICTARIH': $('#BASLANGICTARIH').val(),
        'BITTARIH': $('#BITTARIH').val(),
        'ARANAN': $('#arananNo').val(),
        'ARAYAN': $('#arayanNo').val()
      }

      jx('AramaKaydi/AramaKaydiListele', 'POST', obj).then(res => {
        requestMessage(res)
        $('#aramaKayitlari').DataTable().destroy()
        $('#aramaKayitlari tbody').html('')
        const data = JSON.parse(res.NESNE) !== null ? JSON.parse(res.NESNE) : []
        console.log(data)
        for (let d of data) {
          $('#aramaKayitlari tbody').append(`
              <tr>
                <td>${d.TARIH}</td>
                <td>${d.ARAYAN}</td>
                <td>${d.ARANAN}</td>
              </tr>
            `)
        }
        $('#aramaKayitlari').DataTable()
      })
    })
  }
}
