export const REQUESTURL = 'http://cmu.techlife.com.tr/api/'
export const MAINURL = 'http://cmu.techlife.com.tr'
export const LOGINURL = `${MAINURL}/contents/login.html`
export const K_ANAHTARI = JSON.parse(window.localStorage.getItem('kullanici')) !== null ? JSON.parse(window.localStorage.getItem('kullanici'))[0].K_ANAHTAR : null
export const OTURUMID = JSON.parse(window.localStorage.getItem('kullanici')) !== null ? JSON.parse(window.localStorage.getItem('kullanici'))[0].OTURUMID : null
export const CALLKEY = 'K.Ib5YepU'

export const TR = {
  format: 'dd.mm.yyyy',
  days: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
  daysShort: ['Paz', 'Pts', 'Sal', 'Çrş', 'Prş', 'Cum', 'Cts'],
  daysMin: ['P', 'P', 'S', 'Ç', 'P', 'C', 'C'],
  weekStart: 1,
  months: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
  monthsShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
}
