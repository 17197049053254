import $ from 'jquery'
import 'bootstrap-select'
import toastr from 'toastr'
import { jx, requestMessage } from './global'
import { TR, K_ANAHTARI, OTURUMID } from './constants'

require('datatables.net-dt')()

export default {
  renderDt () {
    $(document).ready(function () {
      $('#OperatorDatatable').DataTable()
      $('.selectpicker').selectpicker()
      operatorListele()
      Operatorler()
    })
  }
}

function Operatorler(){
  jx('/Operator/OperatorListele', 'POST', {
    'K_ANAHTAR': K_ANAHTARI,
    'OTURUM': OTURUMID,
    'ID_OPERATOR': 0
  }).then(res => {
   
    var html ="";
    const data = JSON.parse(res.NESNE) !== null ? JSON.parse(res.NESNE) : []
    $('#ustoperator').append('<option value="0">  Yeni Operatör  </option>');
    for (let d of data) {
      html += '<option value='+ d.TabloID +'>  '+ d.OperatorAdi +'  </option>'
    }
    $('#ustoperator').append(html);
  })
}


function operatorListele () {
  jx('/Operator/OperatorListele', 'POST', {
    'K_ANAHTAR': K_ANAHTARI,
    'OTURUM': OTURUMID,
    'ID_OPERATOR': 0
  }).then(res => {
    $('#OperatorDatatable').DataTable().destroy()
    $('#OperatorDatatable tbody').html('')
    const data = JSON.parse(res.NESNE) !== null ? JSON.parse(res.NESNE) : []
    for (let d of data) {
      $('#OperatorDatatable tbody').append(`
        <tr>
          <td>${d.OperatorAdi}</td>
          <td>${d.UstOperator}</td>
          UstOperator
          <td>
            <div class="dropdown">
              <button class="btn btn-sm btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">
                <i class="fa fa-cog"></i> İşlemler
              </button>
              <div class="dropdown-menu" data-id="${d.TabloID}">
                <a class="dropdown-item  operaorDuzenleBtn">Düzenle</a>
                <a class="dropdown-item operatorSilBtn" >Sil</a>
              </div>
            </div>
          </td>
        </tr>
      `)
    }
    $('#OperatorDatatable').DataTable()
    operatorEkle()
    operatorGuncelle() 
    operatorSil()

  })
}

function operatorSil() {
  $('.operatorSilBtn').off('click')
  $('.operatorSilBtn').on('click', function () {
    let operatorId = $(this).parent().data('id')
     jx('Operator/OperatorSil', 'POST', {
       'K_ANAHTAR': K_ANAHTARI,
       'OTURUM': OTURUMID,
       'ID_OPERATOR': operatorId
     }).then(res => {
       requestMessage(res)
       operatorListele()
     })
  })
}

function operatorEkle () {
  $('#operatorEkleBtn').off('click')
  $('#operatorEkleBtn').on('click', function () {
    if ($('#operatorAd').val() === '') {
      toastr.warning('Lütfen değer giriniz')
    } else {
      const obj = {
        'K_ANAHTAR': K_ANAHTARI,
        'OTURUM': OTURUMID,
        "ID_OPERATOR": $("#ustoperator").val(),
        'OPERATOR_AD': $('#operatorAd').val()
      }
   
       jx('/Operator/OperatorEkle', 'POST', obj).then(res => {
         requestMessage(res)
        
         $('#operatorEkleModal').modal('hide')
         operatorListele()
         Operatorler()
       })
    }
  })
}


function operatorGuncelle() {
  
  $('.operaorDuzenleBtn').off('click')
  $('.operaorDuzenleBtn').on('click', function () {
  $('#operatorDuzenleModal').modal('show')

  jx('/Operator/OperatorListele', 'POST', {
    'K_ANAHTAR': K_ANAHTARI,
    'OTURUM': OTURUMID,
    'ID_OPERATOR': 0
  }).then(res => {
    
    var html ="";
    $('#duzenleustoperator').empty("");
    $("#duzenleidoperator").empty("");
    const data = JSON.parse(res.NESNE) !== null ? JSON.parse(res.NESNE) : []
    $('#duzenleustoperator').append('<option value="0">  Yeni Operatör  </option>');
   
    for (let d of data) {
      html += '<option value='+ d.TabloID +'>  '+ d.OperatorAdi +'  </option>'
    }
    $('#duzenleustoperator').selectpicker('refresh')
    $('#duzenleustoperator').append(html);
  })

  let id = $(this).parent().data('id')
  jx('/Operator/OperatorListele', 'POST', {
    'K_ANAHTAR': K_ANAHTARI,
    'OTURUM': OTURUMID,
    'ID_OPERATOR': id
  }).then(res => {
   

    $('#duzenleustoperator').selectpicker('refresh')
    $('#duzenleustoperator').selectpicker('val', JSON.parse(res.NESNE)[0].UstID)
    $('#duzenleoperatorAd').val(JSON.parse(res.NESNE)[0].OperatorAdi)
     
    $('#duzenleidoperator').val(JSON.parse(res.NESNE)[0].TabloID)
    
     $('#operatorduzenleBtn').off('click')
     $('#operatorduzenleBtn').on('click', function () {
    

        if ($('#duzenleoperatorAd').val() === '') {
         toastr.warning('Lütfen değer giriniz')
       } else {
        
         const obj = {
           'K_ANAHTAR': K_ANAHTARI,
           'OTURUM': OTURUMID,
           "ID_USTOPERATOR":   $("#duzenleustoperator").val(),  
           "ID_OPERATOR": $("#duzenleidoperator").val(),
           'OPERATOR_AD': $('#duzenleoperatorAd').val()
         }
            jx('/Operator/OperatorDuzenle', 'POST', obj).then(res => {
              requestMessage(res)
              $('#operatorDuzenleModal').modal('hide')
              operatorListele()
              Operatorler()
            })
       }
     })
    }) 
  })
}




