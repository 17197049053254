import $ from 'jquery'
import toastr from 'toastr'
import yetki from './yetki'
import kullanicilar from './kullanicilar'
import uygulama from './uygulama'
import operator from './operator'
import hesaplar from './hesaplar'
import arama from './arama'
import aramaKayitlari from './aramaKayitlari'
import { LOGINURL, REQUESTURL } from './constants'

export function loadByHash () {
  checkLogin()
  $(window).on('hashchange', function () {
    makeRouting()
  })
  $(document).ready(function () {
    makeRouting()
  })
}

function makeRouting () {
  const urlHash = window.location.hash.replace('#', '')
  switch (urlHash) {
    case 'anasayfa':
      $('#content').load('./contents/anasayfa.html')
      break
    case 'yetki':
      $('#content').load('./contents/yetki.html', () => {
        yetki.renderDt()
        yetki.yetkiListeleme()
      })
      break
    case 'kullanicilar':
      $('#content').load('./contents/kullanicilar.html', () => {
        kullanicilar.renderDt()
        kullanicilar.kullaniciEkle()
      })
      break
    case 'arama':
      $('#content').load('./contents/arama.html', () => {
        arama()
      })
      break
    case 'uygulama':
      $('#content').load('./contents/uygulama.html', () => {
        uygulama.renderDt()
      })
      break
    case 'operatorler':
      $('#content').load('./contents/operatorler.html', () => {
        operator.renderDt()
      })
      break
    case 'hesaplar':
      $('#content').load('./contents/hesaplar.html', () => {
        hesaplar.renderDt()
      })
      break
    case 'arama-kayitlari':
      $('#content').load('./contents/arama-kayitlari.html', () => {
        aramaKayitlari.renderDt()
        aramaKayitlari.aramaKayitListele()
      })
      break
    case 'login':
      window.location.href = `${LOGINURL}`
      break
    default:
      $('#content').load('./contents/404.html')
      break
  }
}
export function jx(endUrl, method, data) {
  checkLogin()
  return new Promise(function (resolve, reject) {
    $.ajax({
      method: method,
      url: '' + (REQUESTURL + endUrl),
      data: data
    }).done(function (res) {
      resolve(res)
    }).fail(function (err) {
      reject(err)
    })
  })
}

function checkLogin () {
  const userToken = window.localStorage.getItem('kullanici')
  if (!userToken) {
    window.location.href = `${LOGINURL}`
  }
}

export function requestMessage (data) {
  if (data.DURUM) {
    toastr.success(data.MESAJ)
  } else {
    toastr.error(data.MESAJ)
  }
}
