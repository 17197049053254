import $ from 'jquery'
import 'bootstrap-select'
import { jx, requestMessage } from './global'
import { TR, K_ANAHTARI, OTURUMID } from './constants'

require('@chenfengyuan/datepicker')
require('datatables.net-dt')()

export default {
  renderDt () {
    $('.selectpicker').selectpicker()
    $.fn.datepicker.languages['tr-TR'] = TR
    $('[data-toggle="datepicker"]').datepicker({
      language: 'tr-TR'
    })
    jx('YetkiGrup/YetkiGrupListele', 'POST', {
      'K_ANAHTAR': K_ANAHTARI,
      'OTURUM': OTURUMID,
      'ID_YETKIGRUP': 0
    }).then(res => {
      $('#ID_YETKIGRUPLIST').html('')
      const yetkiList = JSON.parse(res.NESNE) !== null ? JSON.parse(res.NESNE) : []
      for (let yetki of yetkiList) {
        $('#ID_YETKIGRUPLIST').append(
          `<option value="${yetki.ID_YETKIGRUP}">${yetki.AD}</option>`
        )
      }
      $('#ID_YETKIGRUPLIST').selectpicker('refresh')
    })
    kullaniciListele()
  },
  kullaniciEkle () {
    $('#kullaniciEkleBtn').off('click')
    $('#kullaniciEkleBtn').on('click', function () {
      const obj = {
        'K_ANAHTAR': K_ANAHTARI,
        'OTURUM_KULLANICI': OTURUMID,
        'TCKIMLIKNO': $('#TCKIMLIKNO').val(),
        'AD': $('#AD').val(),
        'SOYAD': $('#SOYAD').val(),
        'YETKIGRUPLIST': $('#ID_YETKIGRUPLIST').val().toString(),
        'DOGUMGUN': $('#DOGUMTARIHI').val().slice(0, 2),
        'DOGUMAY': $('#DOGUMTARIHI').val().slice(3, 5),
        'DOGUMYIL': $('#DOGUMTARIHI').val().slice(6, 11)
      }
      jx('Kullanici/KullaniciEkle', 'POST', obj).then(res => {
        requestMessage(res)
        $('#yeniKullaniciEkle').modal('hide')
        kullaniciListele()
      })
    })
  }
}

function kullaniciListele () {
  jx('Kullanici/KullaniciListele', 'POST', {
    'K_ANAHTAR': K_ANAHTARI,
    'OTURUM_KULLANICI': OTURUMID,
    'ID_KULLANICI': 0
  }).then(res => {
    $('#kullanicilarDatatable').DataTable().destroy()
    $('#kullanicilarDatatable tbody').html('')
    const data = JSON.parse(res.NESNE) !== null ? JSON.parse(res.NESNE) : []
    for (let d of data) {
      $('#kullanicilarDatatable tbody').append(`
        <tr>
          <td>${d.AD}</td>
          <td>${d.SOYAD}</td>
          <td>
            <div class="dropdown">
              <button class="btn btn-sm btn-secondary dropdown-toggle"
                type="button"
                data-toggle="dropdown">
                <i class="fa fa-cog"></i> İşlemler
              </button>
              <div class="dropdown-menu" data-id="${d.ID_KULLANICI}">
                <a class="dropdown-item kullaniciDuzenleDropdownBtn" href="javascript:;">Düzenle</a>
                <a class="dropdown-item kullaniciSilDropdownBtn" href="javascript:;">Sil</a>
              </div>
            </div>
          </td>
        </tr>
      `)
    }
    kullaniciDuzenle()
    kullaniciGuncelle()
    kullaniciSil()
    $('#kullanicilarDatatable').DataTable()
  })
}
let kID = ''
function kullaniciDuzenle () {
  $('.kullaniciDuzenleDropdownBtn').off('click')
  $('.kullaniciDuzenleDropdownBtn').on('click', function () {
    $('#kullaniciGuncelle').modal('show')
    kID = $(this).parent().data('id')
    jx('Kullanici/KullaniciDetay', 'POST', {
      'K_ANAHTAR': K_ANAHTARI,
      'OTURUM_KULLANICI': OTURUMID,
      'ID_KKULLANICI': kID
    }).then((res) => {
      jx('Kullanici/KullaniciYetkiGrubuDetay', 'POST', {
        'K_ANAHTAR': K_ANAHTARI,
        'OTURUM_KULLANICI': OTURUMID,
        'ID_KKULLANICI': kID
      }).then(data => {
        const yetkiList = JSON.parse(data.NESNE)
        let yekiArr = []
        for (let y of yetkiList) {
          yekiArr.push(y.ID_YETKIGRUP)
        }
        jx('YetkiGrup/YetkiGrupListele', 'POST', {
          'K_ANAHTAR': K_ANAHTARI,
          'OTURUM': OTURUMID,
          'ID_YETKIGRUP': 0
        }).then(val => {
          $('#guncelleID_YETKIGRUPLIST').html('')
          const yetkiList = JSON.parse(val.NESNE) !== null ? JSON.parse(val.NESNE) : []
          for (let yetki of yetkiList) {
            $('#guncelleID_YETKIGRUPLIST').append(
              `<option value="${yetki.ID_YETKIGRUP}">${yetki.AD}</option>`
            )
          }
          $('#guncelleID_YETKIGRUPLIST').selectpicker('refresh')
          $('#guncelleID_YETKIGRUPLIST').selectpicker('val', yekiArr)
        })

        $('#guncelleTCKIMLIKNO').val(`${JSON.parse(res.NESNE)[0].TCKIMLIKNO}`)
        $('#guncelleAD').val(`${JSON.parse(res.NESNE)[0].AD}`)
        $('#guncelleSOYAD').val(`${JSON.parse(res.NESNE)[0].SOYAD}`)
        $('#guncelleID_YETKIGRUPLIST').val(`${JSON.parse(res.NESNE)[0].ID_YETKIGRUPLIST}`)
        // $('#guncelleDOGUMTARIHI').datepicker('setDate', `${JSON.parse(res.NESNE)[0].DOGUMTARIHI}`)
      })
    })
  })
}

function kullaniciGuncelle () {
  $('#kullaniciGuncelleBtn').off('click')
  $('#kullaniciGuncelleBtn').on('click', function () {
    const obj = {
      'K_ANAHTAR': K_ANAHTARI,
      'OTURUM_KULLANICI': OTURUMID,
      'ID_KKULLANICI': kID,
      'TCKIMLIKNO': $('#guncelleTCKIMLIKNO').val(),
      'AD': $('#guncelleAD').val(),
      'SOYAD': $('#guncelleSOYAD').val(),
      'YETKIGRUPLIST': $('#guncelleID_YETKIGRUPLIST').val().toString(),
      'DOGUMGUN': $('#guncelleDOGUMTARIHI').val().slice(0, 2),
      'DOGUMAY': $('#guncelleDOGUMTARIHI').val().slice(3, 5),
      'DOGUMYIL': $('#guncelleDOGUMTARIHI').val().slice(6, 11)
    }
    jx('Kullanici/KullaniciGuncelle', 'POST', obj).then(res => {
      requestMessage(res)
      $('#kullaniciGuncelle').modal('hide')
      kullaniciListele()
    })
  })
}

function kullaniciSil () {
  $('.kullaniciSilDropdownBtn').off('click')
  $('.kullaniciSilDropdownBtn').on('click', function () {
    jx('Kullanici/KullaniciSil', 'POST', {
      'K_ANAHTAR': K_ANAHTARI,
      'OTURUM_KULLANICI': OTURUMID,
      'ID_KKULLANICI': $(this).parent().data('id')
    }).then(res => {
      requestMessage(res)
      kullaniciListele()
    })
  })
}
